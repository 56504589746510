import { render, staticRenderFns } from "./FormDesligamento.vue?vue&type=template&id=e7fcfa22&scoped=true&"
import script from "./FormDesligamento.vue?vue&type=script&lang=js&"
export * from "./FormDesligamento.vue?vue&type=script&lang=js&"
import style0 from "./FormDesligamento.vue?vue&type=style&index=0&id=e7fcfa22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7fcfa22",
  null
  
)

export default component.exports